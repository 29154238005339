import React, { useState, useEffect, useRef } from 'react';
import bridge from '@vkontakte/vk-bridge';
import View from '@vkontakte/vkui/dist/components/View/View';
import ScreenSpinner from '@vkontakte/vkui/dist/components/ScreenSpinner/ScreenSpinner';
import '@vkontakte/vkui/dist/vkui.css';

import Home from './panels/Home';
import Map from './panels/Map';
import VoteResults from './panels/VoteResults';
import ColorPicker from './panels/ColorPicker';
import Polls from './panels/Polls';
import AddVote from './panels/AddVote';
import PollPanel from './panels/PollPanel';
import SelectRegion from './panels/SelectRegion';
import Context from './panels/Context';
import Guide from './panels/Guide';
import Guide2 from './panels/Guide2';
import YourRegion from './panels/YourRegion'

import ModerPolls from './panels/ModerPolls';
import * as scroll_utils from './utils/scroll_locker';

import { Icon28PlaceOutline, Icon28More, Icon16Done, Icon16Cancel, Icon56PlaceOutline, Icon56ErrorOutline, Icon24Done } from '@vkontakte/icons';
import { platform, IOS, Panel, PanelHeader, ModalCard, PopoutWrapper, ModalRoot, Tabbar, TabbarItem, Epic, Root, Alert, Snackbar, Avatar, ActionSheet, ActionSheetItem, Tooltip, Spinner } from '@vkontakte/vkui';

import API_CLASS from './Api';
let URL_PARAMS = window.location.search.slice(1);
// if(URL_PARAMS.length < 10){
// 	URL_PARAMS = 'vk_access_token_settings=&vk_app_id=7593923&vk_are_notifications_enabled=0&vk_is_app_user=0&vk_is_favorite=0&vk_language=ru&vk_platform=mobile_web&vk_ref=catalog_recent&vk_user_id=242306128&sign=YAR7YZQ54eCzcUrGnQoTi9Z1EkWjsna7Le_eYNyVkSM&hash=';
// }	// vk_access_token_settings=&vk_app_id=7593923&vk_are_notifications_enabled=0&vk_is_app_user=0&vk_is_favorite=0&vk_language=ru&vk_platform=mobile_web&vk_ref=other&vk_user_id=242306128&sign=cXXOrq6RfwNGjqZjIw1bBf2vhFS3NM8g0cRD3fQmYTw
const qs = require('querystring');
const isOnline = require('is-online');
var Scroll = require('react-scroll');
var scroll = Scroll.animateScroll;
const urlParams = qs.parse(URL_PARAMS);
const user_id = urlParams['vk_user_id']
const hash = urlParams['hash']

window.colors_variant = ['#46b53a', '#ff4040', '#eb8934', '#3489eb', '#9803fc']

let lastObject = null;
let activeTab = 'home';

const osname = platform();

window.region_names_array = [
	{'id': 1, 'name': 'Адыгея'},
	{'id': 3, 'name': 'Алтай'},
	{'id': 5, 'name': 'Архангельск'},
	{'id': 6, 'name': 'Астрахань'},
	{'id': 8, 'name': 'Белгород'},
	{'id': 9, 'name': 'Брянск'},
	{'id': 10, 'name': 'Бурятия'},
	{'id': 83, 'name': 'Владимир'},
	{'id': 84, 'name': 'Волгоград'},
	{'id': 85, 'name': 'Вологда'},
	{'id': 86, 'name': 'Воронеж'},
	{'id': 19, 'name': 'Горно-Алтай'},
	{'id': 17, 'name': 'Дагестан'},
	{'id': 89, 'name': 'Еврейская автономная область'},
	{'id': 71, 'name': 'Екатеринбург'},
	{'id': 13, 'name': 'Забайкальский край'},
	{'id': 22, 'name': 'Иваново'},
	{'id': 21, 'name': 'Иркутск'},
	{'id': 23, 'name': 'Кабардино-Балкария'},
	{'id': 24, 'name': 'Калининград'},
	{'id': 25, 'name': 'Калмыкия'},
	{'id': 26, 'name': 'Калуга'},
	{'id': 27, 'name': 'Камчатка'},
	{'id': 28, 'name': 'Карачаево-Черкесия'},
	{'id': 29, 'name': 'Карелия'},
	{'id': 30, 'name': 'Кемерово'},
	{'id': 34, 'name': 'Киров'},
	{'id': 36, 'name': 'Коми'},
	{'id': 38, 'name': 'Кострома'},
	{'id': 39, 'name': 'Краснодар'},
	{'id': 40, 'name': 'Красноярск'},
	{'id': 4, 'name': 'Крым'},
	{'id': 41, 'name': 'Курган'},
	{'id': 42, 'name': 'Курск'},
	{'id': 43, 'name': 'Ленинградская область'},
	{'id': 44, 'name': 'Липецк'},
	{'id': 45, 'name': 'Магадан'},
	{'id': 46, 'name': 'Марий Эл'},
	{'id': 47, 'name': 'Мордовия'},
	{'id': 48, 'name': 'Москва'},
	{'id': 49, 'name': 'Московская область'},
	{'id': 50, 'name': 'Мурманск'},
	{'id': 51, 'name': 'Ненецкий автономный округ'},
	{'id': 52, 'name': 'Нижний Новгород'},
	{'id': 54, 'name': 'Новгород'},
	{'id': 55, 'name': 'Новосибирск'},
	{'id': 56, 'name': 'Омск'},
	{'id': 57, 'name': 'Орел'},
	{'id': 58, 'name': 'Оренбург'},
	{'id': 59, 'name': 'Пенза'},
	{'id': 60, 'name': 'Пермь'},
	{'id': 61, 'name': 'Приморье'},
	{'id': 62, 'name': 'Псков'},
	{'id': 63, 'name': 'Ростов'},
	{'id': 64, 'name': 'Рязань'},
	{'id': 65, 'name': 'Сакха'},
	{'id': 67, 'name': 'Самара'},
	{'id': 16, 'name': 'Санкт-Петербург'},
	{'id': 68, 'name': 'Саратов'},
	{'id': 66, 'name': 'Сахалин'},
	{'id': 20, 'name': 'Севастополь'},
	{'id': 53, 'name': 'Северная Осетия'},
	{'id': 69, 'name': 'Смоленск'},
	{'id': 70, 'name': 'Ставрополь'},
	{'id': 72, 'name': 'Тамбов'},
	{'id': 73, 'name': 'Татарстан'},
	{'id': 78, 'name': 'Тверь'},
	{'id': 75, 'name': 'Томск'},
	{'id': 76, 'name': 'Тула'},
	{'id': 77, 'name': 'Тыва'},
	{'id': 79, 'name': 'Тюмень'},
	{'id': 80, 'name': 'Удмуртия'},
	{'id': 81, 'name': 'Ульяновск'},
	{'id': 7, 'name': 'Уфа'},
	{'id': 31, 'name': 'Хабаровск'},
	{'id': 32, 'name': 'Хакасия'},
	{'id': 33, 'name': 'Ханты-Мансийск'},
	{'id': 12, 'name': 'Челябинск'},
	{'id': 11, 'name': 'Чечня'},
	{'id': 15, 'name': 'Чувашия'},
	{'id': 14, 'name': 'Чукотка'},
	{'id': 87, 'name': 'Ямало-Ненецкий автономный округ'},
	{'id': 88, 'name': 'Ярославль'},
]

window.region_names = {
	1: {id: 1, name: "Адыгея"},
	3: {id: 3, name: "Алтай"},
	2: {id: 2, name: "Крым"},
	4: {id: 4, name: "Крым"},
	5: {id: 5, name: "Архангельск"},
	6: {id: 6, name: "Астрахань"},
	7: {id: 7, name: "Уфа"},
	8: {id: 8, name: "Белгород"},
	9: {id: 9, name: "Брянск"},
	10: {id: 10, name: "Бурятия"},
	11: {id: 11, name: "Чечня"},
	12: {id: 12, name: "Челябинск"},
	13: {id: 13, name: "Забайкальский край"},
	14: {id: 14, name: "Чукотка"},
	15: {id: 15, name: "Чувашия"},
	16: {id: 16, name: "Санкт-Петербург"},
	17: {id: 17, name: "Дагестан"},
	19: {id: 19, name: "Горно-Алтай"},
	20: {id: 20, name: "Севастополь"},
	21: {id: 21, name: "Иркутск"},
	22: {id: 22, name: "Иваново"},
	23: {id: 23, name: "Кабардино-Балкария"},
	24: {id: 24, name: "Калининград"},
	25: {id: 25, name: "Калмыкия"},
	26: {id: 26, name: "Калуга"},
	27: {id: 27, name: "Камчатка"},
	28: {id: 28, name: "Карачаево-Черкесия"},
	29: {id: 29, name: "Карелия"},
	30: {id: 30, name: "Кемерово"},
	31: {id: 31, name: "Хабаровск"},
	32: {id: 32, name: "Хакасия"},
	33: {id: 33, name: "Ханты-Мансийск"},
	34: {id: 34, name: "Киров"},
	36: {id: 36, name: "Коми"},
	38: {id: 38, name: "Кострома"},
	39: {id: 39, name: "Краснодар"},
	40: {id: 40, name: "Красноярск"},
	41: {id: 41, name: "Курган"},
	42: {id: 42, name: "Курск"},
	43: {id: 43, name: "Ленинградская область"},
	44: {id: 44, name: "Липецк"},
	45: {id: 45, name: "Магадан"},
	46: {id: 46, name: "Марий Эл"},
	47: {id: 47, name: "Мордовия"},
	48: {id: 48, name: "Москва"},
	49: {id: 49, name: "Московская область"},
	50: {id: 50, name: "Мурманск"},
	51: {id: 51, name: "Ненецкий автономный округ"},
	52: {id: 52, name: "Нижний Новгород"},
	53: {id: 53, name: "Северная Осетия"},
	54: {id: 54, name: "Новгород"},
	55: {id: 55, name: "Новосибирск"},
	56: {id: 56, name: "Омск"},
	57: {id: 57, name: "Орел"},
	58: {id: 58, name: "Оренбург"},
	59: {id: 59, name: "Пенза"},
	60: {id: 60, name: "Пермь"},
	61: {id: 61, name: "Приморье"},
	62: {id: 62, name: "Псков"},
	63: {id: 63, name: "Ростов"},
	64: {id: 64, name: "Рязань"},
	65: {id: 65, name: "Сакха"},
	66: {id: 66, name: "Сахалин"},
	67: {id: 67, name: "Самара"},
	68: {id: 68, name: "Саратов"},
	69: {id: 69, name: "Смоленск"},
	70: {id: 70, name: "Ставрополь"},
	71: {id: 71, name: "Екатеринбург"},
	72: {id: 72, name: "Тамбов"},
	73: {id: 73, name: "Татарстан"},
	75: {id: 75, name: "Томск"},
	76: {id: 76, name: "Тула"},
	77: {id: 77, name: "Тыва"},
	78: {id: 78, name: "Тверь"},
	79: {id: 79, name: "Тюмень"},
	80: {id: 80, name: "Удмуртия"},
	81: {id: 81, name: "Ульяновск"},
	83: {id: 83, name: "Владимир"},
	84: {id: 84, name: "Волгоград"},
	85: {id: 85, name: "Вологда"},
	86: {id: 86, name: "Воронеж"},
	87: {id: 87, name: "Ямало-Ненецкий автономный округ"},
	88: {id: 88, name: "Ярославль"},
	89: {id: 89, name: "Еврейская автономная область"},
}

const api = new API_CLASS(urlParams);
let is_wifi_popout = false;
const App = () => {
	const [activePollsView, setPollsView] = useState('main');
	const [activeHomePanel, setHomePanel] = useState('main');
	const [activeModal, setActiveModal] = useState(null);
	const [activeStory, setActiveStory] = useState('home');
	const [snackbarMain, setSnackbarMain] = useState(null);
	const [fetchedUser, setUser] = useState(null);
	const [popout, setPopout] = useState(<ScreenSpinner/>);
	const [popoutMap, setPopoutMap] = useState(<ScreenSpinner/>);

	const [spinnerPoll, setSpinnerPoll] = useState(null);
	const [userRegion, setUserRegion] = useState(24);
	const [hideRegions, setHideRegions] = useState([]);
	const [isFetching, setIsFetching] = useState(false);
	const [lastRegionChange, setLastRegionChange] = useState(0);
	const [filterPolls, setFilterPolls] = useState('all')
	const [userCity, setUserCity] = useState('')

	const [currentPoll, setCurrentPoll] = useState(null);
	const [addPollState, setAddPollState] = useState({
		question: {text: '', error: '' },
		answers: [
			{id: 0, text: '', color: '#46b53a', error: '' },
			{id: 1, text: '', color: '#ff4040', error: '' }
		],
		chooseColor: null,
	});

	const [activeTooltip, setActiveTooltip] = useState(0);

	const erorr_snackbar = <Snackbar before={<Avatar size={24} style={{backgroundColor: "var(--field_error_border)"}}><Icon16Cancel fill="#ffffff" width={14} height={14} /></Avatar>} onClose={() => setSnackbarMain(null)}><div>Ошибка соединения</div></Snackbar>;
	const something_wrong_snackbar = <Snackbar before={<Avatar size={24} style={{backgroundColor: "var(--field_error_border)"}}><Icon16Cancel fill="#ffffff" width={14} height={14} /></Avatar>} onClose={() => setSnackbarMain(null)}><div>Ошибка соединения</div></Snackbar> ;
	const already_change_snackbar = <Snackbar before={
										<Avatar size={24} style={{backgroundColor: "var(--field_error_border)"}}>
											<Icon16Cancel fill="#ffffff" width={14} height={14} />
										</Avatar>} onClose={() => setSnackbarMain(null)}>
												<div>
													Вы сможете изменить регион через {getTimeEndSelectBan()} д.
													</div>
									</Snackbar> ;
	const [allPollsCount, setAllPollsCount] = useState(0)

	const [polls, setPolls] = useState(
		[]
	);

	const [moderPollsCount, setModerPollsCount] = useState(0)

	const [moderPolls, setModerPolls] = useState(
		[]
	);
	
	const [slideIndex, setSlideIndex] = useState(0);

	//map
	
	const [center, setCenter] = useState([105, 62]);
	const [zoom, setZoom] = useState(2);

	const [current_region, setCurrentRegion] = useState(
		{id: null, name: null}
	);

	const story_functions = {
		'home': setHomePanel,
		'polls': setPollsView
	}

	const [activeSortVariant, setActiveSortVariant] = useState(1);

	const sortVariants = [
		{id: 0, title: 'Популярность', value: 'votes_count'},
		{id: 1, title: 'Дата', value: 'date'},
	]


	// let polls = [
	// 	{id: 0, title: 'Вы любите кошек?'},
	// 	{id: 1, title: 'Вы любите собак?'},
	// 	{id: 2, title: 'Вы любите пиццу?'},
	// 	{id: 3, title: 'Вы любите кошек?'},
	// 	{id: 4, title: 'Вы любите собак?'},
	// 	{id: 5, title: 'Вы любите пиццу?'},
	// ]

	const [history, setHistory] = useState({
		home: [{name: 'main', func: setHomePanel}],
		polls: [{name: 'main', func: setPollsView}],
	})


	useEffect(() => {
		if(window.location.hash){
			api.getPollById(user_id, window.location.hash.substr(1))
			.then( res => {
					setCurrentPoll(res['items'][0]);
					setActiveStory('home')
				}
			)
		}
		api.getUser(user_id).then(
			res => {
				if(res){
					setUserRegion(res['region'])
					setLastRegionChange(res['last_change'])
				}
				else{
					scroll_utils.default.enable();
					setGuidePanel('guide')
					setActiveTooltip(1)
				}
			}
		)
		async function fetchData() {
			const user = await bridge.send('VKWebAppGetUserInfo');
			if (user['city']) {
				setUserCity(user['city']['title']);
			}
			setUser(user);
		}
		fetchData();
		getStartPolls();
		getModerPolls()
		window.addEventListener('popstate', e => {goBack()});
		function updateOnlineStatus(event) {
			if(navigator.onLine & is_wifi_popout){
				is_wifi_popout = false;
				setPopout(null)
			}
			else{
				setPopout(<PopoutWrapper
					alignY="center" alignX="center" hasMask={true}
					>
						<div style={{backgroundColor: 'var(--background_content)', borderRadius: 15, padding: 10, paddingTop: 0}}><h3>Подключение прервано</h3>
					<Spinner size='medium' style={{marginBottom: 5}}/></div>
				
					</PopoutWrapper>)
				is_wifi_popout = true;
			}
			
		  }
		
		  window.addEventListener('online',  updateOnlineStatus);
		  window.addEventListener('offline', updateOnlineStatus);
		// bridge.subscribe(({ detail: { type, data }}) => {
		// 	if (type === "VKWebAppViewRestore") {
		// 		setPopout(<ScreenSpinner/>)
		// 		isOnline()
		// 		.then(online => {
		// 			if(!online){
		// 				setPopout(<Alert
		// 					actionsLayout="vertical"
		// 				  >
		// 					<h2>Подключение прервано</h2>
		// 					<p>Проверьте свое Интернет-соединение.</p>
		// 					<Spinner/>
		// 				  </Alert>)
		// 				onlineChecker()
		// 			}
		// 		else{
		// 			setPopout(null)
		// 		}})

		// 	}
		// });
		
	}, []);

	useEffect(() => {
		getStartPolls()
		
	}, [filterPolls, activeSortVariant])

	function goBack() {
		let current_history = history[activeTab];
		if( current_history.length === 1 ) { 
		  bridge.send("VKWebAppClose", {"status": "success"}); 
		  console.log('service close')
		} else if( current_history.length > 1 ) {
			current_history.pop()
			let currentEventHistory = current_history[current_history.length - 1];
			let {func, name} = currentEventHistory;
			if(name === 'main'){
				bridge.send('VKWebAppSetSwipeSettings', {"history": false});
			}
			func(name);	
		}
	  }

	function goToPage(name) { 
		setSnackbarMain(null)
		bridge.send('VKWebAppSetSwipeSettings', {"history": true});
		lastObject = {name: name}
		history[activeStory].push({name: name, func: story_functions[activeStory]});		
		window.history.pushState(lastObject, name );
		story_functions[activeStory](name);
	};

	function onStoryChange(e) {
		if(e === activeTab){
			if(activeTab == 'polls' & activePollsView === 'main'){
				scroll.scrollToTop();
			}
			else{
				goToPage('main')
				history[activeTab] = [{name: 'main', func: story_functions[activeTab]}];
			}

		}
		else{
			setSnackbarMain(null)
			activeTab = e;
			setActiveStory(e);
		}
	}

	function setGuidePanel(panel_name) {
		if(panel_name == 'select_region'){
			scroll_utils.default.disable();
		}
		setHomePanel(panel_name)
	}

	function openSelectRegion() {
		if(getTimeEndSelectBan() > 0){
			setSnackbarMain(already_change_snackbar)
		}
		else{
			goToPage('select_region')
		}
	}

	function getTimeEndSelectBan() {
		return Math.ceil((lastRegionChange - Math.round(new Date().getTime()/1000.0))  / 3600)
	}

	function getStartPolls(isPoput=true) {
		// user_id 
		setIsFetching(true)
		setPopout(isPoput ? <ScreenSpinner/>: null)
		api.getPolls(user_id, filterPolls, sortVariants[activeSortVariant]['value'])
		.then(res => {
			// window.scrollTo(0, 0)
			setIsFetching(false)
			setAllPollsCount(res['count'])
			setPolls(res['items'])})
		.catch(() => setSnackbarMain(erorr_snackbar))
		.finally(() => {
			setIsFetching(false)
			setPopout(null)})
		console.log(isFetching)
		if(polls === undefined){
			// polls = []
			setPolls([])
			polls = [];
		}
		


	}

	function getModerPolls(isPoput=true) {
		// user_id 
		setIsFetching(true)
		setPopout(isPoput ? <ScreenSpinner/>: null)
		api.getPolls(user_id, 'owner_moder', sortVariants[activeSortVariant]['value'])
		.then(res => {
			setIsFetching(false)
			setModerPollsCount(res['count'])
			setModerPolls(res['items'])})
		.catch(() => setSnackbarMain(erorr_snackbar))
		.finally(() => {
			setIsFetching(false)
			setPopout(null)})
		
		if(polls === undefined){
			// polls = []
			setPolls([])
			polls = [];
		}
		


	}

	function updatePoll(poll_id) {
		let index = polls.findIndex(x => x.id === poll_id);
		if(index !== null){
			api.getPollById(user_id, poll_id)
			.then(res => {
				let new_val = polls.slice();
				new_val[index] = res['items'][0]
				if(currentPoll){
					if(currentPoll.id === poll_id){
						setCurrentPoll(res['items'][0]);
					}
				}
				setPolls(new_val)

			})
		}
	}

	function addVote(poll_id, answer) {
		setPopout(<ScreenSpinner/>)
		api.addVote(user_id, poll_id, userRegion, answer)
		.then(res => {
			if(res == 1){
				updatePoll(poll_id)

				// setSnackbarMain(<Snackbar><div>Ок</div></Snackbar> ) .findIndex(x => x.id === '45');
			}
			else{
				setSnackbarMain(something_wrong_snackbar)
			}
		})
		.catch(error => setSnackbarMain(erorr_snackbar))
		.finally(() => setPopout(null))

	}

	function delVote(poll_id) {
		setPopout(<ScreenSpinner/>)
		if(!hideRegions.includes(userRegion)){
			setHideRegions([...hideRegions, userRegion]);
		}
		api.delVote(user_id, poll_id)
		.then(res => {
			if(res == null){
				updatePoll(poll_id)
				// setSnackbarMain(<Snackbar><div>Ок</div></Snackbar> )
			}
			else{
				setSnackbarMain(something_wrong_snackbar)
			}
		})
		.catch(error => setSnackbarMain(erorr_snackbar))
		.finally(() => setPopout(null))

	}

	async function addPoll(question, answers, colors) {
		setPopout(<ScreenSpinner/>)
		setAddPollState({
			question: {text: '', error: '' },
			answers: [
				{id: 0, text: '', color: '#46b53a' },
				{id: 1, text: '', color: '#ff4040' }
			]
		})
		api.addPoll(user_id, 1, question, answers, colors)
		.then(res => {
			if(res == 1){
				window.history.back()
				setSnackbarMain(<Snackbar 
					layout="vertical"
					before={<Avatar size={24} style={{backgroundColor: "var(--dynamic_green)"}}><Icon16Done fill="#fff" width={14} height={14} /></Avatar>}
					onClose={() => setSnackbarMain(null)} >
						Отправлено на модерацию
				</Snackbar>);
				getStartPolls()
				getModerPolls()
			}
			else{
				setSnackbarMain(something_wrong_snackbar)
			}
		})
		.catch(error => setSnackbarMain(erorr_snackbar))
		.finally(() => 
		setPopout(null))
	}

	async function delPoll(poll_id) {
		setPopout(<Alert
        actionsLayout="vertical"
        actions={[{
          title: 'Удалить',
          autoclose: true,
          mode: 'destructive',
          action: () => delPollRequest(poll_id),
        }, {
          title: 'Отмена',
          autoclose: true,
          mode: 'cancel'
        }]}
        onClose={() => setPopout(null)}
      >
        <h2>Подтвердите действие</h2>
        <p>Вы уверены, что хотите удалить опрос?</p>
      </Alert>)
	}

	async function delPollRequest(poll_id) {
		setPopout(<ScreenSpinner/>)
		if(currentPoll){
			if(currentPoll.id === poll_id){
				setCurrentPoll(null);
			}
		}

		setHideRegions('all')
		await api.delPoll(poll_id)
		.then(res => {
			if(res == 0){
				let index = moderPolls.findIndex(x => x.id === poll_id);
				if(index !== -1){
					moderPolls.splice(index, 1)
					setModerPollsCount(moderPollsCount - 1);
					if(moderPolls.length == 0){
						goToPage('main')
					}
				}
				else{
					index = polls.findIndex(x => x.id === poll_id);
					if(index !== -1){
						polls.splice(index, 1)
						setAllPollsCount(allPollsCount - 1);
					}
				}
				
				
				setSnackbarMain(<Snackbar before={<Avatar size={24} style={{backgroundColor: "var(--field_error_border)"}}><Icon16Cancel fill="#ffffff" width={14} height={14} /></Avatar>} onClose={() => setSnackbarMain(null)}><div>Удалено</div></Snackbar> )
				
			}
			else{
				setSnackbarMain(something_wrong_snackbar)
			}
		})
		.catch(error => setSnackbarMain(erorr_snackbar))
		.finally(() => setPopout(null))
		await api.getPollsCount(user_id, filterPolls)
				.then(res => setAllPollsCount(res))
				.catch(() => setSnackbarMain(something_wrong_snackbar))
	}

	/*function addPolls() {
		api.getPolls(user_id, 5, polls.length).then(res => {
			setPolls(oldArray => [...oldArray].concat(res['items']));
		})
	}*/
	function addPolls() {
		api.getPolls(user_id, filterPolls, sortVariants[activeSortVariant]['value'], 5, polls.length).then(res => {
			setPolls(oldArray => [...oldArray].concat(res['items']));
		})
	}

	/*function addModerPolls() {
		api.getPolls(user_id, 5, polls.length).then(res => {
			setPolls(oldArray => [...oldArray].concat(res['items']));
		})
	}*/

	function addModerPolls() {
		api.getPolls(user_id, filterPolls, sortVariants[activeSortVariant]['value'], 5, polls.length).then(res => {
			setPolls(oldArray => [...oldArray].concat(res['items']));
		})
	}

	function showRegionVoteResults (current_region) {
		setCurrentRegion(current_region)
		setActiveModal('vote-results')
	}

	function chooseAnswerColor (answer) {
		addPollState['chooseColor'] = answer;
		setAddPollState(Object.assign({}, addPollState));
		setActiveModal('color-picker')
	}
	function pollDetailsPopout (poll_id, owner_id) {
		setPopout(
			<ActionSheet onClose={() => setPopout(null)}>
      			<ActionSheetItem autoclose onClick={() => sharePoll(poll_id)}>
      				Поделиться
      			</ActionSheetItem>
				<ActionSheetItem autoclose href={`https://vk.com/id${owner_id}`} target='_blank'>
      				Страница автора
      			</ActionSheetItem>
      			{osname === IOS && <ActionSheetItem autoclose mode="cancel">Отменить</ActionSheetItem>}
      		</ActionSheet>
		)
	}
	function sortVariantsPopout () {
		setPopout(
			<ActionSheet onClose={() => setPopout(null)}>
				{
					sortVariants.map(variant => {
						return <ActionSheetItem key={variant.id} autoclose onClick={() => setActiveSortVariant(variant.id)} /*before={activeSortVariant === variant.id? <Icon24Done/> : null}*/>
							{variant.title}
						</ActionSheetItem>
					})
				}
      			{osname === IOS && <ActionSheetItem autoclose mode="cancel">Отменить</ActionSheetItem>}
      		</ActionSheet>
		)
	}

	function regionAutoSelect(auto=true){
        if (userCity.length > 0 & window.region_names_array.findIndex(x => x.name.toLowerCase() === userCity.toLowerCase()) !== -1) {
            setActiveModal('your-region');
          }
          else if(!auto){
			setActiveModal('no-find-region');
			// setSnackbarMain(
			// 	<Snackbar 
			// 	  before={<Avatar size={24} style={{backgroundColor: "var(--field_error_border)"}}>
			// 		  <Icon16Cancel fill="#ffffff" width={14} height={14} />
			// 		</Avatar>} 
			// 		onClose={() => setSnackbarMain(null)}>
			// 		  <div>
			// 			Не удалось автоматически определить регион
			// 		  </div>
			// 	</Snackbar>
			//   );
            
        }
    }
	function sharePoll(poll_id) {
		setSnackbarMain(null);
		setPopout(<ScreenSpinner />)
		setTimeout(() => 	setPopout(null), 3000
		)
		isOnline()
		.then(online => {
			if(online)	{
				bridge.send("VKWebAppShare", {"link": `https://vk.com/app7593923#${poll_id}`})
				.then(() => setSnackbarMain(<Snackbar 
					layout="vertical"
					before={<Avatar size={24} style={{backgroundColor: "var(--dynamic_green)"}}><Icon16Done fill="#fff" width={14} height={14} /></Avatar>}
					onClose={() => setSnackbarMain(null)} >
						Успешно отправлено!
				</Snackbar>))
				.catch(() => setSnackbarMain(<Snackbar 
					layout="vertical"
					before={<Avatar size={24} style={{backgroundColor: "var(--field_error_border)"}}><Icon16Cancel fill="#ffffff" width={14} height={14} /></Avatar>}
					onClose={() => setSnackbarMain(null)} >
						Не удалось поделиться
				</Snackbar>))
			}	
			else	{
				setSnackbarMain(<Snackbar 
					layout="vertical"
					before={<Avatar size={24} style={{backgroundColor: "var(--field_error_border)"}}><Icon16Cancel fill="#ffffff" width={14} height={14} /></Avatar>}
					onClose={() => setSnackbarMain(null)} >
						Нет подключения к Интернету
				</Snackbar>)
			}
			setPopout(null);
			})
		.finally(() => {
			setPopout(null)
		})
	}

	async function selectUserRegion(region_id, is_close) {
		setHideRegions([...hideRegions, userRegion]);
		setUserRegion(region_id);
		setPopout(<ScreenSpinner/>)
		if(is_close){
			window.history.back()
		}
		await api.getUser(user_id).then(
			res => {
				if(!res){
					api.addUser(user_id, region_id)
					.catch(error => {setSnackbarMain(erorr_snackbar)
						console.log(error)})
				}
				else{
					api.setUserValue(user_id, 'region', region_id)
				}
			}
		)
		let new_time_change = Math.round(new Date().getTime()/1000.0) + 3600;
		setLastRegionChange(new_time_change);

		await api.setUserValue(user_id, 'last_change', new_time_change)
		.catch(error => {setSnackbarMain(erorr_snackbar)
		console.log(error)})
		.finally(() => setPopout(null));
	}

	function showResultOnMap(data) {
		setCurrentPoll(data);
		// setTimeout(() => {setPopout(null)}, 1000)
		onStoryChange('home')
	}
	
	const modal = (
		<ModalRoot
			activeModal={activeModal}
		>
			<ModalCard 
				id='vote-results'
				onClose={() => setActiveModal(null)}
			>
				<VoteResults title={current_region.name} answers={current_region.answers} count={current_region.count}/>
			</ModalCard>
			<ModalCard 
				id='color-picker'
				onClose={() => setActiveModal(null)}
			>
				<ColorPicker/>
			</ModalCard>
			<ModalCard
        		id='your-region'
        		onClose={() => setActiveModal(null)}
        		icon={<Icon56PlaceOutline />}
				header={userCity + ' - это Ваш город?'}
				caption='Этот город указан в Вашем профиле.'
        		actions={[{
        		  title: 'Нет',
        		  mode: 'secondary',
        		  action: () => setActiveModal(null),
        		}, {
        		  title: 'Да',
        		  mode: 'primary',
        		  action: () => (selectUserRegion(window.region_names_array[window.region_names_array.findIndex(x => x.name.toLowerCase() === userCity.toLowerCase())]['id']), setGuidePanel('main'), setActiveModal(null)),
        		}]}
        	/>
			<ModalCard 
				id='no-your-region'
				onClose={() => setActiveModal(null)}
        		icon={<Icon56PlaceOutline />}
				header={'Похоже, Ваш регион не поддерживается.'}
				caption='Выберите ближайший к Вам город из списка.'
        		actions={[{
        		  title: 'Хорошо',
        		  mode: 'secondary',
        		  action: () => setActiveModal(null),
        		}]}
			/>
			<ModalCard 
				id='no-find-region'
				onClose={() => setActiveModal(null)}
        		icon={<Icon56ErrorOutline fill={'var(--destructive)'} />}
				header={'Ошибка'}
				caption='Не удалось получить город из Вашего профиля, возможно город отсутствует в нашей базе.'
        		actions={[{
        		  title: 'Закрыть',
        		  mode: 'destructive',
        		  action: () => setActiveModal(null),
        		}]}
			/>
		</ModalRoot>
	);

	const tabbar = (
        <Tabbar>
			<TabbarItem
				onClick={() => onStoryChange('home')}
				selected={activeStory==='home'}
				data-story='home'
			><Icon28PlaceOutline/></TabbarItem>
			<TabbarItem
				onClick={() => onStoryChange('polls')}
				selected={activeStory==='polls'}
				data-story='polls'
			>
			
				<Icon28More/>
			</TabbarItem>
		</Tabbar>
	);
	return (
		<Context.Provider value={{
					polls, 
					center, 
					setCenter, 
					zoom, 
					setZoom, 
					slideIndex, 
					setSlideIndex, 
					showRegionVoteResults, 
					setPollsView,
					goToPage,
					addPolls,
					snackbarMain,
					addVote,
					delVote,
					hideRegions,
					setHideRegions,
					addPollState,
					setAddPollState,
					delPoll,
					user_id,
					setActiveModal,
					api,
					chooseAnswerColor,
					setFilterPolls,
					currentPoll, 
					showResultOnMap,
					hash,
					pollDetailsPopout,
					setActiveTooltip,
					activeTooltip,
					setPopoutMap,
					popoutMap,
					fetchedUser,
					userCity,
					setSnackbarMain,
					regionAutoSelect,
					sortVariants,
					setActiveSortVariant,
					activeSortVariant,
					sortVariantsPopout
					}}>
			<Epic activeStory={activeStory} tabbar={activeHomePanel == 'main' ? tabbar : null}>
				<View id='home' activePanel={activeHomePanel} header={false} modal={modal} popout={popout}>
					<Home id='main'
					popout={popout}/>
					<Guide id='guide'	
						   setGuidePanel={setGuidePanel}/>
					<Guide2 id='guide2'	
						   setGuidePanel={setGuidePanel}/>
					<SelectRegion id='select_region' 
							setUserRegion={selectUserRegion}
							setGuidePanel={setGuidePanel}/>
				</View>
				<Root id='polls' activeView={activePollsView} header={false} popout={popout}>
					<View id='main' activePanel='main'>
						<Polls id='main'
							spinnerPoll={spinnerPoll}
							allPollsCount={allPollsCount}
							userRegion={userRegion}
							getStartPolls={getStartPolls}
							isFetching={isFetching}
							openSelectRegion={openSelectRegion}
							moderPollsCount={moderPollsCount}
							setSnackbarMain={setSnackbarMain}
							/>
					</View>
					<View id='add_poll' activePanel='main' modal={modal}>
						<AddVote id='main'
							addPoll={addPoll}/>
					</View>
					<View id='select_region' activePanel='main'>
						<SelectRegion id='main' 
							setUserRegion={selectUserRegion}/>
					</View>
					<View id='poll' activePanel='main'>
						<PollPanel id='main'/>
					</View>
					<View id='moder' activePanel='main'>
						<ModerPolls id='main'
							getModerPolls={getModerPolls}
							moderPolls={moderPolls}
							addModerPolls={addModerPolls}
							isFetching={isFetching}
							/>
					</View>
				</Root>
			</Epic>
		</Context.Provider>
	);
}

export default App;

