import React, { useRef, useEffect, useState, useContext } from 'react';
import PropTypes from 'prop-types';

import './style.css';
import {Card, Title } from '@vkontakte/vkui';
import Icon16Done from '@vkontakte/icons/dist/16/done';
import Context from './Context';

const colors = [
    {id: 0, color: '#46b53a'},
    {id: 1, color: '#ff4040'},
    {id: 2, color: '#eb8934'},
    {id: 3, color: '#3489eb'},
    {id: 4, color: '#9803fc'},
]

const ColorPicker = props => {
    const {addPollState, setAddPollState, setActiveModal} = useContext(Context);
    function pickColor(color) {
        addPollState['answers'][addPollState['chooseColor']].color = color;
        addPollState['chooseColor'] = null;
        setAddPollState(Object.assign({}, addPollState));
        setActiveModal(null)
    }
    
    return (
    <div id={props.id}>
        <Title level='2' weight='bold' style={{paddingBottom: '12px'}}>Выберите цвет</Title>
        {
            colors.map(color =>
                {
                    var picked = addPollState['answers'].findIndex(answer => answer.color == color.color) == -1;
                    return <div key={color.id} className='color_dot' style={{backgroundColor: color.color, marginRight: '5px', position: 'relative'}} onClick={() =>
                        picked?
                        pickColor(color.color)
                        :
                        null

                    }>
                        {picked?
                        null
                        :
                        <Icon16Done style={{color: '#fff', position: 'absolute', top: 'calc(50% - 8px)', left: 'calc(50% - 8px)'}}/>}
                    </div>
                }
            )
        } 
    </div>
    );
}

ColorPicker.propTypes = {
    id: PropTypes.string,
};

export default ColorPicker;
