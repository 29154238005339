import React, { useContext, useState, useEffect } from 'react';
import PropTypes from 'prop-types';

import Context from './Context';

import './style.css';
import { PanelHeader, Cell, List, Search, PanelHeaderClose, Panel, SimpleCell, Snackbar, Avatar, Link } from '@vkontakte/vkui';
import { Icon16Cancel, Icon24ErrorCircle, Icon24Globe } from '@vkontakte/icons';
import Icon28ErrorOutline from '@vkontakte/icons/dist/28/error_outline';
const SelectRegion = props => {
    const [searchText, setSearchText] = useState('')
    const {setActiveModal, userCity, snackbarMain, regionAutoSelect} = useContext(Context);
    function searchOnChange(e) {
        let text = e.target.value.substr(0, 100);

        setSearchText(text);
    }

    function getItems() {
        let search = searchText.toLowerCase();
        return window.region_names_array.filter(({name}) => name.toLowerCase().indexOf(search) > -1);
    }
    return (
        <Panel id={props.id} separator={false}>

        <PanelHeader
        left={props.setGuidePanel ? null :<PanelHeaderClose onClick={() => window.history.back()}/>}
            separator={false}
          >
            Выбор региона
          </PanelHeader>
          <Search value={searchText} onChange={searchOnChange} after={null}/>
          {getItems().length > 0 &&
            <List>
              {props.setGuidePanel? <Cell onClick={() => setActiveModal('no-your-region')} before={<Icon28ErrorOutline/>}><Link>Нет вашего города?</Link></Cell> : null}
              {props.setGuidePanel? <Cell onClick={() => regionAutoSelect(false)} before={<Icon24Globe/>}><Link>Определить по профилю</Link></Cell> : null}

              {getItems().map(region => <SimpleCell key={region.id} onClick={() => {
                props.setUserRegion(region.id, props.setGuidePanel === undefined)
                if(props.setGuidePanel !== undefined){
                    props.setGuidePanel('main')
                }
                }}>{region.name}</SimpleCell>)}
            </List>
          }
          {snackbarMain}
        </Panel>
    );
}


export default SelectRegion;
