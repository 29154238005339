import React from 'react';
import PropTypes from 'prop-types';
import Panel from '@vkontakte/vkui/dist/components/Panel/Panel';
import Button from '@vkontakte/vkui/dist/components/Button/Button';
import Div from '@vkontakte/vkui/dist/components/Div/Div';
import Caption from '@vkontakte/vkui/dist/components/Typography/Caption/Caption';
import Title from '@vkontakte/vkui/dist/components/Typography/Title/Title';
import Text from '@vkontakte/vkui/dist/components/Typography/Text/Text';

import map from '../img/map.png';
import { Card, CardGrid } from '@vkontakte/vkui';


const HelpGuide = props => (
	<Panel id={props.id} >
        <Div id='second' className='mainDiv'>
            <Title className='title_guide' level="1" weight="bold">Добро пожаловать в <span id='dynamicName' className='title_guide' level="1" >Воутмап!</span></Title>  
            <CardGrid style={{marginTop: 20}}>
                <Card size="l" mode="shadow"className='card_guide'>
                        <Div className='card_guide_content'  style={{display: 'flex', justifyContent: 'center', alignItems: 'center', color: 'black', width: 300, height: 200}}>
                        <img src={map}></img>
                        </Div>
                </Card>
            </CardGrid> 
            <Text className='description elem_for_show'>Учавствуй в социальных опросах, создавай собственные и смотри результаты по регионам на интерактивной карте!</Text>
            <div className='buttons visuallyhidden hidden elem_for_show'>
                <Button size="xl" mode="outline" style={{marginTop: 40}} onClick={() => props.setGuidePanel('select_region')}>Начать</Button>
                {/* <Caption level="1" weight="semibold" className='skip_guide' onClick={() => props.closeGuide()}>пропустить обучение</Caption> */}
            </div>
        </Div>
	</Panel>
);

HelpGuide.propTypes = {
	id: PropTypes.string.isRequired,
	fetchedUser: PropTypes.shape({
		id: PropTypes.string,
		first_name: PropTypes.string,
		last_name: PropTypes.string,
	}),
};

export default HelpGuide;