import React, { useRef, useEffect, useState, useContext } from 'react';
import PropTypes from 'prop-types';
import {Panel, PanelHeader, Group, CardGrid, ScreenSpinner, PopoutWrapper, Tooltip, Spinner} from '@vkontakte/vkui';

import './style.css';
import VoteGallery from './VoteGallery';
import Map from './Map';
import Context from './Context';
import VoteCard from './VoteCard';

const Home = props => {
	const {polls, snackbarMain, currentPoll, popoutMap} = useContext(Context);
	return (
		<Panel id={props.id} separator={false}>
			<Map id='map' coords={null} data={currentPoll ? currentPoll : polls[0]}/>
			<Group>
				<CardGrid id='home-card-grid' /*style={{marginTop: 'calc(var(--panelheader_height_android) + var(--safe-area-inset-top) - 30px) '}}*/>

				{polls ? 	<VoteCard id='home-card' data={currentPoll ? currentPoll : polls[0]} key={1} size='l' height={150} hideIcons={true}/>: <ScreenSpinner/>}

    		</CardGrid>
			{polls ? (popoutMap === 'yes' ? <Spinner size='large' style={{marginTop: 70}}/> : null) : null}
			</Group>
			{snackbarMain}
		</Panel>
	);
}
Home.propTypes = {
	id: PropTypes.string.isRequired,
};

export default Home;
