const $body = document.querySelector('body');
let scrollPosition = 0;

export default {
  play() {
    setTimeout( function() {
      var logoTitle = 'TextTools!';
      var logoRandom = '';
          var possible = "-+*/|}{[]~\\\":;?/.><=+-_)(*&^%$#@!)}";
          let span_element = document.getElementById('dynamicName');
  
      function generateRandomTitle(i, logoRandom) {
        setTimeout( function() {
                  // console.log(span_element.innerText )
          span_element.textContent = logoRandom;
        }, i*100 );
      }
  
      for( var i=0; i < logoTitle.length+1; i++ ) {
        logoRandom = logoTitle.substr(0, i);
        for( var j=i; j < logoTitle.length; j++ ) { 
          logoRandom += possible.charAt(Math.floor(Math.random() * possible.length)); 
        }
        generateRandomTitle(i, logoRandom);
        logoRandom = '';
      }
  
    }, 0 );
  },
  enable() {
    scrollPosition = window.pageYOffset;
    $body.style.overflow = 'hidden';
    $body.style.position = 'fixed';
    $body.style.top = `-${scrollPosition}px`;
    $body.style.width = '100%';
  },
  disable() {
    $body.style.removeProperty('overflow');
    $body.style.removeProperty('position');
    $body.style.removeProperty('top');
    $body.style.removeProperty('width');
    window.scrollTo(0, scrollPosition);
  }
};