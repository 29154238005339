import React, { useContext } from 'react';
import PropTypes from 'prop-types';

import {Panel, PanelHeader, CardGrid, PanelHeaderClose, Caption, Spinner, Button, Group, PullToRefresh, Header, Placeholder} from '@vkontakte/vkui';
import Context from './Context';
import VoteCard from './VoteCard';

import InfiniteScroll from 'react-infinite-scroll-component';


const AddVote = props => {
    const {goToPage, snackbarMain} = useContext(Context);
    
    async function refreshPolls() {
		await props.getModerPolls(false)
	}
	const polls = props.moderPolls;
    return (
	    <Panel id={props.id} >
			
	    	<PanelHeader separator={false} left={<PanelHeaderClose onClick={() => window.history.back()} />}>На проверке</PanelHeader>
    		<CardGrid style={{padding: 0}}>
            <PullToRefresh onRefresh={refreshPolls} isFetching={props.isFetching}>
				<Group >
				<CardGrid>
					<InfiniteScroll
					style={{overflow: "visible"}}
					dataLength={polls.length} //This is important field to render the next data
					next={() => props.addModerPolls()}
					hasMore={polls.length < props.allPollsCount}
					loader={<Spinner size="regular" style={{paddingTop: 20, paddingBottom: 20}}/>}
					endMessage={polls.length > 0 &&
						<Caption level="1" weight="regular" className='caption'>
							опросов: {polls.length}
						</Caption>
					}
					>
						{polls.length > 0?
						polls.map(vote => {
							return <div>
								<VoteCard data={vote} key={vote.id} size='l' height={150}/>
							</div>
						}): 
							<Placeholder
								action={<Button size="l"  onClick={() => goToPage('add_poll')}>Создать опрос</Button>}
								>
									Пока что здесь нет опросов
							</Placeholder>}
					</InfiniteScroll>
						
				</CardGrid>
				</Group>
				</PullToRefresh>
            </CardGrid>
			{snackbarMain}
	    </Panel>
    );
}
AddVote.propTypes = {
	id: PropTypes.string.isRequired,
};

export default AddVote;
