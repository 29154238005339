import React, { useRef, useEffect, useState, useContext } from 'react';
import PropTypes from 'prop-types';

import mapboxgl from 'mapbox-gl';
import "mapbox-gl/dist/mapbox-gl.css";
import './style.css';
import Context from './Context';
import { ScreenSpinner, Spinner } from '@vkontakte/vkui';

mapboxgl.accessToken = 'pk.eyJ1IjoiYWxleC1idWwiLCJhIjoiY2tleGNpaTI1MDAwazJ5bzJucWgyMmh3aiJ9.N1pXrpkKMv4NfecZmZa3TA'; // access token

const Map = props => {
	window.region_names_array.sort(function (a, b) {
		if (a.name > b.name) {
		  return 1;
		}
		if (a.name < b.name) {
		  return -1;
		}
		// a должно быть равным b
		return 0;
	  });
	const {center, setCenter, zoom, setZoom, showRegionVoteResults, api, polls, hideRegions, setHideRegions, currentPoll, setPopoutMap} = useContext(Context);
	const [map, setMap] = useState(null);
	const mapContainer = useRef(null);
	/*let map_coords = [50, 50]
	
	if(props.coords){
		map_coords = [...props.coords];
	}*/
	useEffect(() => {

		const initializeMap = ({ setMap, mapContainer }) => {
			
			const map = new mapboxgl.Map({
				container: mapContainer.current,
				height: 100,
				style: "mapbox://styles/alex-bul/ckfl8yt4f012i19mk5gkzau2f", // stylesheet location
				center: center,
				zoom: zoom,
				maxZoom: 4.5,
				minZoom: 2
			});

			map.on('load', function () {	
				setMap(map);
				map.resize();
			});

			map.on('load', 'state-fills', function() {
				console.log('A load event occurred.');
			});
			
			map.on('move', () => {
				setCenter(map.getCenter())
				setZoom(map.getZoom())

			});

		};

		

		if (!map) initializeMap({ setMap, mapContainer})
	}, [map]);
	useEffect(() => {
		if(polls[0] !== undefined & map !== null){
			addLayersRegion()
			showResult(currentPoll ? currentPoll : polls[0]);
		}
	  }, [polls, map]);

	function addLayersRegion() {
		var region_id = null;
		var layers = map.getStyle().layers;
		var firstSymbolId = layers[layers.length - 4].id;

		
		
		try{
			setPopoutMap('yes')
			map.addSource('states', {
				'type': 'geojson',
				'data':
				'https://raw.githubusercontent.com/VladYoSlav/Russian-regions-GEOJSON/master/geo_data.json'
			});
			map.addLayer({
				'id': 'state-fills',
				'type': 'fill',
				'source': 'states',
				'layout': {},
				'paint': {
					'fill-color': ['coalesce', ['feature-state', 'color'], '#000'],
					'fill-opacity': ['coalesce', ['feature-state', 'opacity'], 0.05],
				}
			}, firstSymbolId)
	
			map.addLayer({
				'id': 'state-borders',
				'type': 'line',
				'source': 'states',
				'layout': {},
				'paint': {
					'line-color': ['coalesce', ['feature-state', 'color'], '#000'],
					'line-width': 2,
					'line-opacity': ['coalesce', ['feature-state', 'opacity'], 0.05],
				}
			}, firstSymbolId);

		}
		catch{
			setPopoutMap(null)
			console.log('error')
		}
		let eventFunction = function() {
			if(map.loaded()) {
				setPopoutMap(null)
				console.log('map loaded')
				map.off('render', eventFunction);
			}
		}
		map.on('render', eventFunction);
		map.on('click', 'state-fills', function (e) {
			region_id = e.features[0].id
			if(window.regionsResult[region_id] === undefined){
				showRegionVoteResults(window.region_names[region_id])
			}
			else{
				showRegionVoteResults(Object.assign(window.regionsResult[region_id], window.region_names[region_id]))
			}
			map.flyTo({
				center: e.lngLat
			});
		});
		map.on('load', 'state-fills', function() {
			console.log('A load event occurred.');
		});
	}

	function index_max(arr) {
		var i, maxV, maxP;
		for( i = 0; i < arr.length; i++) {
		  if( typeof maxV === "undefined" || arr[i] > maxV ) {
			maxV = arr[i];
			maxP = i;
		  }
		}
		
		return maxP;
	  }

	function fillRegion (id, color, opacity) {
		map.setFeatureState(
			{ source: 'states', id: id },
			{ color: color, opacity: opacity}
		);
	}

	async function showResult(poll_data) {
		if(hideRegions !== null & hideRegions !== 'all'){
			hideRegions.map(region => fillRegion(region, '#000', 0.05));
		}
		else if(hideRegions == 'all'){
			map.removeFeatureState({
				source: 'states'
				});
			setHideRegions(null)
		}
		await api.getResult(poll_data.id).then(result_data =>  {
			window.regionsResult = Object.assign({'is_voted': poll_data.is_voted}, result_data);
				for(let key in result_data){
					let win_data = result_data[key]['answers'][index_max(result_data[key]['answers'].map(row => row.count))]

					var color = '';
					var count = 0;

					result_data[key]['answers'].map(answer => {
						if (answer['count'] >= count){
							color = answer['color'];
							count = answer['count'];
						}
					});
					fillRegion (key, color, win_data['count'] / result_data[key]['count'])
				}
		});
	}
	
	return (
		<div id = {props.id} ref={el => (mapContainer.current = el)}/>
	);
};

Map.propTypes = {
	id: PropTypes.string.isRequired,
	// coords: PropTypes.string.isRequired,
};

export default Map;

/*export default class Map extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			lng: 50,
			lat: 50,
			zoom: 8,
		};
	}
	
	shouldComponentUpdate() {
		return false;
	}

	componentDidMount() {
		const map = new mapboxgl.Map({
			container: this.mapContainer,
			style: 'mapbox://styles/mapbox/streets-v11',
			center: [this.state.lng, this.state.lat],
			zoom: this.state.zoom
		});
	 
		map.on('move', () => {
			this.setState({
				lng: map.getCenter().lng.toFixed(4),
				lat: map.getCenter().lat.toFixed(4),
				zoom: map.getZoom().toFixed(2),
			});
		});
	}
	 
	render() {
		return (
			<div>
				<div className='sidebarStyle'>
					<div>Longitude: {this.state.lng} | Latitude: {this.state.lat} | Zoom: {this.state.zoom}</div>
				</div>
				<div ref={el => this.mapContainer = el} className='mapContainer' />
			</div>
		)
	}
}*/