import React, { useRef, useEffect, useState, useContext } from 'react';
import PropTypes from 'prop-types';

import {Panel, PanelHeader, Avatar, CardGrid, Cell, Div, FormLayout, FormLayoutGroup, Input, PanelHeaderClose, Button, Caption, Title} from '@vkontakte/vkui';
import Context from './Context';
import VoteCard from './VoteCard';

import Icon28AddOutline from '@vkontakte/icons/dist/28/add_outline';

const PollPanel = props => {
	const {setPollsView, polls, currentPoll, setCurrentPoll} = useContext(Context);

    return (
	    <Panel id={props.id} separator={false}>
			
	    	<PanelHeader left={<PanelHeaderClose onClick={() => window.history.back()} />}>Опрос</PanelHeader>
			<Title level='2' weight='bold' className='vote-card-title'>{currentPoll.question}</Title>
	    </Panel>
    );
}
PollPanel.propTypes = {
	id: PropTypes.string.isRequired,
};

export default PollPanel;
