import React, { useRef, useEffect, useState, useContext } from 'react';
import PropTypes from 'prop-types';

import {Panel, PanelHeader, Avatar, CardGrid, Cell, Div, FormLayout, FormLayoutGroup, Input, PanelHeaderClose, Button, Caption} from '@vkontakte/vkui';
import Context from './Context';
import VoteCard from './VoteCard';

import Icon28AddOutline from '@vkontakte/icons/dist/28/add_outline';
import { Icon16Add } from '@vkontakte/icons';

const AddVote = props => {
	const {setPollsView, polls, addPollState, setAddPollState, chooseAnswerColor} = useContext(Context);
	const [title, setTitle] = useState(null)

	function onChange(e, elem) {
		if(elem == 'question'){
			addPollState['question'].text = e.target.value.substr(0, 50);
			addPollState['question'].error = false;
			setAddPollState(Object.assign({}, addPollState));
		}
		else{
			let index = addPollState['answers'].findIndex(x => x.id === elem);

			addPollState['answers'][index].text = e.target.value.substr(0, 25);
			addPollState['answers'][index].error = false;
			setAddPollState(Object.assign({}, addPollState));
		}
	}

	function AddVariant() {
		let already_use = addPollState['answers'].map(row => row.color)
		addPollState['answers'].push({id: addPollState['answers'].length, text: '', color: window.colors_variant.filter(row => !already_use.includes(row))[0] })
			setAddPollState(Object.assign({}, addPollState));
	}

	function delVariant(elem) {
		let index = addPollState['answers'].findIndex(x => x.id === elem);
		addPollState['answers'].splice(index, 1)
		setAddPollState(Object.assign({}, addPollState));
	}

	function sendPoll() {
		let answers = addPollState['answers'].map(row => row.text.replaceAll(';', ',')).join(';');
		let colors = addPollState['answers'].map(row => row.color).join(';');
		let question = addPollState['question'].text;
		let flag = true
		if(question.trim().length === 0){
			addPollState.question.error = true;
			flag = false
		}
		for (let index = 0; index < addPollState['answers'].length; index++) {
			const element = addPollState['answers'][index];
			if(element.text.trim().length === 0){
				addPollState['answers'][index].error = true;
				flag = false
			}
			
		}

		if(flag){
			props.addPoll(question, answers, colors);
		}
		else{
			setAddPollState(Object.assign({}, addPollState))
		}
	}
	
    return (
	    <Panel id={props.id} separator={false}>
			
	    	<PanelHeader left={<PanelHeaderClose onClick={() => window.history.back()} />}>Создание опроса</PanelHeader>
    		<FormLayout>
				<Cell top='Вопрос' className='title-cell'>
					<Input 
							type='text' 
							name='title'
							placeholder='Вопрос'
							// status={title ? '' : 'error'}
							onChange={e => onChange(e, 'question')}
							value={addPollState.question.text}
							status={addPollState.question.error ? 'error' : 'default'}
							bottom={addPollState.question.error ? 'Заполните поле' : ''}

					/>
				</Cell>
					
					<FormLayoutGroup top='Варианты'>
					{addPollState['answers'].map(row => 
							<Cell removable={(addPollState['answers'].length > 2 && row.id === 2)} onRemove={() => delVariant(row.id)} className='answer-cell'>
								<div >
									<div style={{float: 'left', width: 'calc(100% - 47px)'}}>
										<Input 
											// style={{}} //width: addPollState['answers'].length > 2 ? '50vw' : '60vw'
											type='text' 
											placeholder='Вариант ответа' 
											key={row.id} 
											value={row.text} 
											onChange={e => onChange(e, row.id)}
											status={row.error ? 'error' : 'default'}
											bottom={row.error ? 'Заполните поле' : ''}/>
									</div>
									<div className='color_dot' style={{backgroundColor: row.color, marginTop: '7px'}} onClick={() => chooseAnswerColor(row.id)}></div>
								</div>
							</Cell>
						)}
					</FormLayoutGroup>
				{/*<div style={{display: 'flex', justifyContent: 'center'}}></div>*/}
				<FormLayoutGroup>
					<Button size='xl' disabled={addPollState['answers'].length === 3} mode='commerce' onClick={AddVariant} before={<Icon16Add/>}>Добавить вариант</Button>
					<Button id='send-poll-button' size='xl' onClick={sendPoll}>Создать опрос</Button>
				</FormLayoutGroup>
				<Caption style={{textAlign: 'center', fontSize: 14, color: 'gray', margin: 10}}>
					Каждый опрос проходит обязательную модерацию перед попаданием в каталог. Проверка длится 1-3 дня.</Caption>
    		</FormLayout>
	    </Panel>
    );
}
AddVote.propTypes = {
	id: PropTypes.string.isRequired,
};

export default AddVote;
