import React, {useContext} from 'react';
import PropTypes from 'prop-types';
import Context from './Context';

import './style.css';
import {Button, Card, Div, Title, Tooltip, Caption } from '@vkontakte/vkui';
import ProgressBar from './ProgressBar';
import { Icon24Cancel, Icon24FavoriteOutline, Icon24PollOutline, Icon24MoreHorizontal } from '@vkontakte/icons';

function calculate_procent(count_that, all_votes) {
    let result = (count_that / all_votes) * 100;
    if(!Number.isNaN(result)){
        return Math.round(result);

    }
    return 0
}

function getLabelVotes(count_votes) {
    let result = ''
    if(count_votes == 1){
        result += 'проголосовал '
    }
    else{
        result += 'проголосовали '
    }
    result += count_votes
    if(2 <= count_votes & count_votes < 5){
        result += ' человека'
    }
    else{
        result += ' человек'
    }
    return result;
}

const VoteCard = props => {
    const {addVote, delVote, delPoll, user_id, showResultOnMap, pollDetailsPopout, polls, activeTooltip, setActiveTooltip} = useContext(Context);
    let result = null;
    let {data, key, size, hideIcons} = props;
    if(data){
        let all_votes = data.answers.map(row => row.count).reduce(function(acc, val) { return acc + val; }, 0)
        let poll_val = null
        if(data.moder == 1){
            
            poll_val = data.answers.map(row => 

                <div key={row.text} style={{paddingTop: '12px', paddingLeft: '12px'}}>
                <ProgressBar hidden={true} text={row.text} width='calc(100% - 12px)' height={30} value={''} />
            </div>)
        }
        else if(data.is_voted !== false){
            poll_val =  data.answers.map(row => 

                                <div key={row.text} style={{paddingTop: '12px', paddingLeft: '12px'}}>
                            <ProgressBar hidden={false} onClick={data.is_voted === data.answers.indexOf(row) ? () => delVote(data.id): null} text={row.text} width='calc(100% - 12px)' height={30} value={calculate_procent(row.count, all_votes)} count={row.count} color={row.color} voted={data.is_voted === data.answers.indexOf(row)}/>
                        </div>)
                        
        }
        else{
            poll_val = data.answers.map(row => 

                                <div key={row.text} style={{paddingTop: '12px', paddingLeft: '12px'}}>
                                <ProgressBar  hidden={true} onClick={() => addVote(data.id, row.text)} text={row.text} width='calc(100% - 12px)' height={30} value={''} voted={data.is_voted === data.answers.indexOf(row)}/>
                            </div>)
        }
    function makeDateString(datetime) {
        let date = datetime.split(' ')[0];
        let time = datetime.split(' ')[1];
        let day = date.split('-')[2];
        let month = date.split('-')[1];
        let year = date.split('-')[0];
        let hours = time.split(':')[0];
        let minutes = time.split(':')[1];

        return day+'.'+month+'.'+year+' '+hours+':'+minutes;
    }
    if(poll_val){
        result = <Card 
                // onClick={() => (goToPage('poll'), setCurrentPoll(data))}
                size={size}
                key={data.id.toString()}
                id={key}
                className='vote-card'
                mode="shadow"> 
                            <Title level='2' weight='bold' className='vote-card-title'>{data.question}</Title>
                            {hideIcons | data.moder == 1 ? null :
                            
                                // data.moder == 1 ? <div style={{top: 15, right:10, position: 'absolute', display: 'flex', alignItems: 'center'}}><Button disabled>на модерации</Button></div> :
                                <div style={{top: 15, right:10, position: 'absolute', display: 'flex', alignItems: 'center'}}>
                                <Icon24MoreHorizontal onClick={() => pollDetailsPopout(data.id, data.owner_id)} style={{paddingRight: '8px'}} fill='var(--accent)' width={32} height={32}/>
                                <Icon24PollOutline onClick={() => showResultOnMap(data)} fill='var(--accent)' width={32} height={32}/>
                                </div>
                                                                    
                            }
                            <Caption level='3' weight='bold' className='vote-card-date'>
                                {
                                    makeDateString(data.date)  
                                }
                            </Caption>
                        {poll_val}
                {data.moder != 1 ? <Caption level="1" weight="regular" className='caption' style={{marginTop: 10, paddingBottom: 10}}>
                                    {all_votes > 0 ? getLabelVotes(all_votes) :'Пока еще никто не проголосовал, будьте первыми!'}
				</Caption>: <Caption level="1" weight="regular" className='caption' style={{marginTop: 10, paddingBottom: 10}}>
                                    Опрос находится на модерации, ожидайте!
				</Caption>}
                {hideIcons ? null :<div style={{display: 'flex', justifyContent: 'center', paddingBottom: 10}}>
                { user_id == parseInt(data.owner_id) | user_id == 242306128 ?  <Button mode='destructive' onClick={() => delPoll(data.id)}>Удалить опрос</Button> : null }

                </div>}

             </Card>;
    }    
    
    }
    return result;
};

VoteCard.propTypes = {
    id: PropTypes.string,
    size: PropTypes.string.isRequired,
    height: PropTypes.number.isRequired,
};

export default VoteCard;
