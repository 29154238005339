
import React, {useContext } from 'react';
import PropTypes from 'prop-types';
import Panel from '@vkontakte/vkui/dist/components/Panel/Panel';
import Button from '@vkontakte/vkui/dist/components/Button/Button';
import Div from '@vkontakte/vkui/dist/components/Div/Div';
import Caption from '@vkontakte/vkui/dist/components/Typography/Caption/Caption';
import Title from '@vkontakte/vkui/dist/components/Typography/Title/Title';
import Text from '@vkontakte/vkui/dist/components/Typography/Text/Text';
import {Snackbar, Avatar } from '@vkontakte/vkui';
import {Icon28AdvertisingOutline, Icon28BasketballBallOutline, Icon28BombOutline, Icon28BrainOutline, Icon28CubeBoxOutline, Icon28FavoriteOutline, Icon28GameOutline, Icon28GhostOutline, Icon28GlobeOutline, Icon28GiftOutline, Icon16Cancel} from '@vkontakte/icons';
import Context from './Context';

const HelpGuide = props => {
    const {regionAutoSelect, userCity, snackbarMain, setSnackbarMain} = useContext(Context);
    
    return (
	    <Panel id={props.id} >
            <Div id='first' className='mainDiv'>
                <div className='animated-guide-icon'><Icon28AdvertisingOutline width={50} height={50}/></div>
                <div className='animated-guide-icon'><Icon28BasketballBallOutline width={50} height={50}/></div>
                <div className='animated-guide-icon'><Icon28BombOutline width={50} height={50}/></div>
                <div className='animated-guide-icon'><Icon28BrainOutline width={50} height={50}/></div>
                <div className='animated-guide-icon'><Icon28CubeBoxOutline width={50} height={50}/></div>
                <div className='animated-guide-icon'><Icon28FavoriteOutline width={50} height={50}/></div>
                <div className='animated-guide-icon'><Icon28GameOutline width={50} height={50}/></div>
                <div className='animated-guide-icon'><Icon28GhostOutline width={50} height={50}/></div>
                <div className='animated-guide-icon'><Icon28GlobeOutline width={50} height={50}/></div>
                <div className='animated-guide-icon'><Icon28GiftOutline width={50} height={50}/></div>
                <Title className='title_guide' level="1" weight="bold">Добро пожаловать в <span id='dynamicName' className='title_guide' level="1" >Воутмап!</span></Title>   
                <Text className='description elem_for_show'>Учавствуй в социальных опросах, создавай собственные и смотри результаты по регионам на интерактивной карте!</Text>
                <div className='buttons  '>
                    <Button size="xl" mode="outline" style={{marginTop: 40}} onClick={() => (
                        props.setGuidePanel('select_region'),
                        regionAutoSelect()
                    )}>Выбрать регион</Button>
                    <Caption level="2" weight="regular" style={{color: 'gray', marginTop: 5}}>Вы сможете его поменять позже</Caption>
                </div>
            </Div>
	    </Panel>
    );
};

HelpGuide.propTypes = {
	id: PropTypes.string.isRequired,
	fetchedUser: PropTypes.shape({
		id: PropTypes.string,
		first_name: PropTypes.string,
		last_name: PropTypes.string,
	}),
};

export default HelpGuide;