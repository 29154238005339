import React, { useRef, useEffect, useState } from 'react';
import PropTypes from 'prop-types';

import './style.css';
import {Card, Title, Caption } from '@vkontakte/vkui';
import ProgressBar from './ProgressBar';

function calculate_procent(count_that, all_votes) {
    let result = (count_that / all_votes) * 100;
    if(!Number.isNaN(result)){
        return Math.round(result);
    }
    return 0
}
function getLabelVotes(count_votes) {
    let result = ''
    if(count_votes == 1){
        result += 'проголосовал '
    }
    else{
        result += 'проголосовали '
    }
    result += count_votes
    if(2 <= count_votes & count_votes < 5){
        result += ' человека'
    }
    else{
        result += ' человек'
    }
    return result;
}

const VoteResults = props => (
    <div id={props.id}>
        <Title level='2' weight='bold' style={{paddingBottom: '12px'}}>{props.title}</Title>
        {props.answers !== undefined ? 
                <div>
                    {props.answers.map(row => 

                    <div key={row.text} style={{paddingTop: '12px'}}>
                    <ProgressBar text={row.text} width='100%' height={30} value={calculate_procent(row.count, props.count)} color={row.color} voted={false} count={row.count}/>
                    </div>)}
                    {/* <div style={{paddingTop: '12px'}}>
                        <ProgressBar text='Да' width='100%' height={30} value={50} color='#4ca34d' voted={false}/>
                    </div>
                    <div style={{paddingTop: '8px'}}>
                        <ProgressBar text='Нет' width='100%' height={30} value={50} color='#f93c3c' voted={false}/>
                    </div> */}
                <Caption level="1" weight="regular" className='caption' style={{marginTop: 10}}>
                    {getLabelVotes(props.count)}
				</Caption>
                </div> 
                : 
                <Title>
                    Данных нет
                    </Title>}
        
    </div>
);

VoteResults.propTypes = {
    id: PropTypes.string,
};

export default VoteResults;
