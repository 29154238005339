import React, { useRef, useEffect, useState, useContext } from 'react';
import PropTypes from 'prop-types';

import {Panel, PanelHeader, PullToRefresh, Placeholder, Group, CardGrid, Header, PanelHeaderButton, Spinner, PanelHeaderContent, PanelHeaderContext, List,  Link, SimpleCell , Cell, Snackbar, Button, Tooltip, Counter, Avatar} from '@vkontakte/vkui';
import Context from './Context';
import VoteCard from './VoteCard';

import Caption from '@vkontakte/vkui/dist/components/Typography/Caption/Caption';
import Title from '@vkontakte/vkui/dist/components/Typography/Title/Title';

import Icon24Globe from '@vkontakte/icons/dist/24/globe';
import Icon24Users from '@vkontakte/icons/dist/24/users';
import Icon24Share from '@vkontakte/icons/dist/24/share';

import InfiniteScroll from 'react-infinite-scroll-component';

import {Icon28AddOutline, Icon16Dropdown, Icon24Done, Icon28UserOutline, Icon28MenuOutline, Icon16Cancel, Icon24Bug, Icon24Sort, Icon28Favorite, Icon28FavoriteOutline, Icon24StatisticsOutline} from '@vkontakte/icons';
var Scroll = require('react-scroll');
var scroll = Scroll.animateScroll;
const Polls = props => {
	const {goToPage, polls, addPolls, snackbarMain, setFilterPolls, activeTooltip, setActiveTooltip, sortVariantsPopout, sortVariants, activeSortVariant } = useContext(Context);
	
	const [contextOpened, setContextOpened] = useState(false);
	const [mode, setMode] = useState('all');
	const flood_cnackbar = <Snackbar before={<Avatar size={24} style={{backgroundColor: "var(--field_error_border)"}}>
		<Icon16Cancel fill="#ffffff" width={14} height={14} /></Avatar>} onClose={() => props.setSnackbarMain(null)}><div>Достигнуто максимальное количество опросов на модерации!</div></Snackbar>;

	function toggleContext() {
		setContextOpened(!contextOpened)
	}

	function select (e) {
		// const mode = e.currentTarget.dataset.mode;
		scroll.scrollToTop()
		// refreshPolls(e.currentTarget.dataset.mode)
		setMode(e.currentTarget.dataset.mode)
		setFilterPolls(e.currentTarget.dataset.mode);
		requestAnimationFrame(toggleContext);
		// setTimeout(() => props.getStartPolls(mode))
	}

	async function refreshPolls() {
		await props.getStartPolls(false)
	}
	
    return (
	    <Panel id={props.id} separator={false}>
			<PanelHeader 
				separator="hide" 
				left={<PanelHeaderButton>
							<Icon28AddOutline onClick={props.moderPollsCount == 3 ? () => props.setSnackbarMain(flood_cnackbar) :() => goToPage('add_poll')}/>		
					</PanelHeaderButton>
				}>
					<PanelHeaderContent
            			aside={<Icon16Dropdown style={{ transform: `rotate(${contextOpened ? '180deg' : '0'})` }} />}
            			onClick={toggleContext}
            		>
            			{mode === 'all' ? 'Все опросы' : mode === 'owner' ? 'Мои опросы' : 'Принял участие'}
            		</PanelHeaderContent>
			</PanelHeader>
			<PanelHeaderContext opened={contextOpened} onClose={toggleContext}>
          		<List>
          			<Cell
          				before={<Icon28MenuOutline/>}
          				asideContent={mode === 'all' ? <Icon24Done fill="var(--accent)" /> : null}
          				onClick={select}
          				data-mode='all'
          			>
          				Все опросы
          			</Cell>
          			<Cell
          				before={<Icon28UserOutline/>}
          				asideContent={mode === 'owner' ? <Icon24Done fill="var(--accent)" /> : null}
          				onClick={select}
          				data-mode='owner'
          			>
          				Мои опросы
          			</Cell>
					  <Cell
          				before={<Icon28FavoriteOutline/>}
          				asideContent={mode === 'part_in' ? <Icon24Done fill="var(--accent)" /> : null}
          				onClick={select}
          				data-mode='part_in'
          			>
          				Принял участие
          			</Cell>
          		</List>
          	</PanelHeaderContext>
				<Group 
				// header={<Header mode="secondary">Настройки</Header>}
					separator='hide'>
					<SimpleCell  indicator={window.region_names[props.userRegion].name} expandable
					onClick={props.openSelectRegion}
					before={<Icon24Globe/>}>
						Ваш регион
					</SimpleCell >
					{/* <SimpleCell  indicator={sortVariants[activeSortVariant]['title']} expandable
					onClick={() => sortVariantsPopout()}
					before={<Icon24Sort/>}>
						Сортировка
					</SimpleCell > */}
					{props.moderPollsCount > 0 ? 
					<SimpleCell expandable 
					before={<Icon24Bug/>} 
					indicator={<Counter mode='primary'>{props.moderPollsCount}</Counter>}
					onClick={() => goToPage('moder')}
					>Опросы на модерации</SimpleCell>:
					null} 
					{/* <SimpleCell  expandable
					href='https://vk.com/club198738428'
					target='_blank'
					description="Узнайте больше о приложении и разработчиках!"
					before={<Icon24Users/>}>
						Сообщество приложения
					</SimpleCell >
					<SimpleCell  expandable
					onClick={props.shareApp}
					description="Расскажите о нас!"
					before={<Icon24Share/>}>
						Поделиться приложением
					</SimpleCell > */}
				</Group>
				<PullToRefresh onRefresh={refreshPolls} isFetching={props.isFetching}>
				<Tooltip	alignY='top'
							alignX='right'
							offsetY={-53}
							cornerOffset={6}
            				text='Нажми сюда, чтобы посмотреть результаты на карте!'
            				isShown={activeTooltip == 1 & polls.length > 0}
            				onClose={() => setActiveTooltip(0)}
            			>
				<Group header={<Header mode="secondary" aside={<Link onClick={() => sortVariantsPopout()}>{sortVariants[activeSortVariant]['title'].toLowerCase()}<Icon16Dropdown /></Link>}>Доступные опросы</Header>}>
				<CardGrid>
					<InfiniteScroll
					style={{overflow: "visible"}}
					dataLength={polls.length} //This is important field to render the next data
					next={() => addPolls()}
					hasMore={polls.length < props.allPollsCount}
					loader={<Spinner size="regular" style={{paddingTop: 20, paddingBottom: 20}}/>}
					endMessage={polls.length > 0 &&
						<Caption level="1" weight="regular" className='caption' style={{padding: '50px'}}>
							опросов: {polls.length}
						</Caption>
					}
					>
						{polls.length > 0?
						polls.map(vote => {
							return <VoteCard key={vote.id.toString()}  data={vote}  size='l' height={150}/>
							
						}): mode !== 'part_in' ? 
							<Placeholder
								action={<Button size="l"  onClick={() => goToPage('add_poll')}>Создать опрос</Button>}
								>
									Пока что здесь нет опросов
							</Placeholder>: <Placeholder
								icon={<Icon24StatisticsOutline/>}
								>
									Принимай участие в опросах и они появятся здесь!
							</Placeholder>}
					</InfiniteScroll>
						
				</CardGrid>
				</Group>
				</Tooltip>
				</PullToRefresh>
				{snackbarMain}

	    </Panel>
    );
}
Polls.propTypes = {
	id: PropTypes.string.isRequired,
};

export default Polls;
